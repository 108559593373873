@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: "roboto-light";
  src: url("./assets/fonts/Roboto/Roboto-Light.ttf");
}

@font-face {
  font-family: "roboto-regular";
  src: url("./assets/fonts/Roboto/Roboto-Regular.ttf");
}

@font-face {
  font-family: "roboto-medium";
  src: url("./assets/fonts/Roboto/Roboto-Medium.ttf");
}

@font-face {
  font-family: "roboto-bold";
  src: url("./assets/fonts/Roboto/Roboto-Bold.ttf");
}

/* Add this CSS to your styles */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
}

.slider.round:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider.round {
  background-color: #2196F3;
}

input:focus + .slider.round {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider.round:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
